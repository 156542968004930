// ToneComponent.js
import React from 'react';
import { Typography, FormControl, Select, MenuItem, Badge } from '@mui/material';
import BadgeComponent from './BadgeComponent';

const ToneComponent = ({ value, onChange }) => (
  <FormControl fullWidth margin="normal">
    <Typography variant="body" sx={{ fontSize: { xs: '18px', sm: '16px' }, marginBottom: { xs: '5px', sm: '10px' }, marginTop: { xs: '0', sm: '10px' }, fontWeight: "500" }}>
      Tone & Style
      <Typography variant="p" sx={{ marginLeft: '10px', color: '#023047' , fontSize: { xs: '14px', sm: '16px' }, marginBottom: { xs: '5px', sm: '10px' }, marginTop: { xs: '0', sm: '10px' }, fontWeight: "300" }}>New Options!</Typography>
    </Typography>
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        }
      }}
    >
      <MenuItem value="20d1e2ee3f6f">Crystal Clear <span style={{ fontSize: '0.8em', color: 'gray' }}> (Recommended)</span></MenuItem>
      <MenuItem value="19174c2970e8">Assertive</MenuItem>
      <MenuItem value="3d478134035e">Friendly</MenuItem>
      {/* <MenuItem value="2132ed86f99a">Professional</MenuItem> */}
      <MenuItem value="62c5fd50e0a6">Strait to the Point</MenuItem>
      {/* <MenuItem value="c9e91f0cd58b">Creative</MenuItem> */}
      <MenuItem value="8862336f37ee">Confident</MenuItem>
      <MenuItem value="5b6f9a7c1234">Positive <BadgeComponent badgeContent="New" backgroundColor="#219ebc" color="#fff" />
      </MenuItem><MenuItem value="d2e7a9b8c654">Negative <BadgeComponent badgeContent="New" backgroundColor="#219ebc" color="#fff" /></MenuItem>
      <MenuItem value="a55bdaf847c4">Funny</MenuItem>
      
      {/* <MenuItem value="cbc121c32658">Article</MenuItem>
      <MenuItem value="b3a68oed2253">Novelist</MenuItem> */}
      {/* <MenuItem value="2132ed86f99a">Story Telling<BadgeComponent badgeContent="Cool" backgroundColor="#6b9080" color="#fff" /></MenuItem> */}
      <MenuItem value="55ba7d2c8c79">News Scoop<BadgeComponent badgeContent="Cool" backgroundColor="#6b9080" color="#fff" /></MenuItem>
      <MenuItem value="17e31fa7334c">Academic <BadgeComponent /></MenuItem>
      <MenuItem value="d328a6d4eb64">Convincing <BadgeComponent /></MenuItem>
      <MenuItem value="4751ce40dd2c">Unconvincing <BadgeComponent /></MenuItem>
      <MenuItem value="2f642ff482d9">Objective <BadgeComponent /></MenuItem>
      <MenuItem value="12492bb06d41">Legalese <span style={{ fontSize: '0.8em', color: 'gray' }}> (Not for Legal Use)</span> </MenuItem>
      <MenuItem value="b0704b91e958">Selling <BadgeComponent badgeContent="New" backgroundColor="#219ebc" color="#fff" /></MenuItem>
      <MenuItem value="d1b4a7f9ck23">Instagram Post <BadgeComponent badgeContent="Cool" backgroundColor="#6b9080" color="#fff" /></MenuItem>
      {/* <MenuItem value="c9b72kxf3287">Custom <BadgeComponent badgeContent="New!" backgroundColor="#219ebc" color="#fff" /></MenuItem>
      <MenuItem value="f7m65ytn6790">From Pain to Opportunity <BadgeComponent badgeContent="Super!" backgroundColor="#ef476f" color="#fff" /></MenuItem> */}
    </Select>
  </FormControl>
);

export default ToneComponent;
