import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const sentences = [
  "Craft the ideal voice and tone for your emails and presentations!",
  "The fastest and most advanced paraphrasing tool ever!",
  "SayItSlick is so good at rephrasing, even Shakespeare would be impressed!",
  "Would you like to ensure your posts conveyed clearly?",
  "Would you like your emails to sound more Confident?",
  "Perhaps you like to give your social media posts a cooler and more engaging vibe?",
  "Do you wish to sound more Confidetnt at work?",
  "Wanna beef up your arguments with some juicy facts that'll make your point clear?",
  "SayItSlick is the top rephrasing tool available!"
];


function IntroText() {

  const [index, setIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState(sentences[0]);
  const [textVisible, setTextVisible] = useState(true);

  useEffect(() => {
    const readTime = 7000; // 6 seconds to read the text
    const fadeTime = 700; // fade in/out

    const interval = setInterval(() => {
      setTextVisible(false); // Start fading out

      setTimeout(() => {
        setIndex((prev) => (prev + 1) % sentences.length); // Update to next sentence
        setDisplayedText(sentences[(index + 1) % sentences.length]);
        setTextVisible(true); // Start fading in
      }, fadeTime);

    }, readTime + fadeTime);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <Typography variant="h5" gutterBottom sx={{
      fontFamily: 'Roboto',
      fontSize: {
        xs: '20px',
        sm: '23px'
      },
      fontWeight: '300',
      height: {
        xs: '80px',
        sm: '50px'
      },
      padding: '10px',
      marginLeft:'60px',
      marginRight:'60px',
      marginTop: '-25px',
      display: 'flex',
      alignItems: 'top',
      justifyContent: 'center',
      textAlign: 'center', // Ensure text is always centered
      opacity: textVisible ? 1 : 0,
      transition: 'opacity 0.8s ease-in-out',
      width: '80%'  // Ensure the Typography element takes full width
    }}>
      {displayedText}
    </Typography>
  );

}
  
  export default IntroText;
  
