const moreDramaticMessages = [
    "Spinning up more hamsters...",
    "Hold on, rocket launching...",
    "Waking up the code monsters...",
    "Charging turbo boosters...",
    "Loading digital wisdom...",
    "Preparing time machine...",
    "Nearly there...",
    "Almost done...",
    "Yalla Yalla...",
    "Finishing touches...",
    "Nearly finished...",
    "Putting on final touches...",
    "Tweaking the last part...",
    "Hold on, it's coming...",
    "Boosting the backend..."

];

export default moreDramaticMessages;