const errorMessages = [
    "Hold up, you need to enter something first!",
    "Looks like you forgot to type in your thoughts!",
    "We're all ears! What did you want to say?",
    "Please share your thoughts before continuing.",
    "Your input is missing! Please type something.",
    "Oops! It seems you didn't enter any text.",
    "We can't read minds yet! Type something first.",
    "Mind speaking up? We didn't catch that.",
    "Your message is empty. What's on your mind?",
    "Type your sentence to proceed.",
    "Hello? Anything to say? Please type your message.",
    "Your input box is lonely. Fill it with words!",
    "We can't read minds yet! Type something first.",
    "Awaiting your input... Please type your thoughts.",
    "Start by typing your sentence. Can't proceed without it!"
];

export default errorMessages;