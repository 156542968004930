import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance with custom palette
const theme = createTheme({
  palette: {
    secondary: {
      main: '#ff5722', // A vivid color for the button
    },
  },
});

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent !== 'true') {
      setOpen(true);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    localStorage.setItem('cookieConsent', 'true');
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose} variant="contained">
        GOT IT!
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={60000}
        onClose={handleClose}
        message="We're not bakers, but we still serve cookies on this site!"
        action={action}
      />
    </ThemeProvider>
  );
};

export default CookieConsent;
