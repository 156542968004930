const lessDramaticMessages = [
    "Brewing the magic...",
    "Working on it...",
    "Just a sec…",
    "Loading whimsy...",
    "Warming up engines...",
    "Tidying up bits...",
    "Connecting the dots...",
    "Mixing words and letters...",
    "Wiring the magic",
    "Hiring robots...",
    "Training neural networks...",
    "Adjusting the wires...",
    "Bridging the gaps...",
    "Hold tight, brewing...",
    "Setting things up...",
    "Crafting magic now...",
    "Just a sec...",
    "Hold please, crafting...",
    "All hands on deck...",
    "Loading... stay tuned...",
    "Assembling the bits..."

];

export default lessDramaticMessages;