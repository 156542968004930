import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Container, Box } from '@mui/material';
import axios from 'axios';
import OutputComponent from './OutputComponent';
import ActionButton from './ActionButton';
import UserSentenceComponent from './UserSentenceComponent';
import ToneComponent from './ToneComponent';
import LanguageComponent from './LanguageComponent';
import errorMessages from '../staticText/errorMessages';

const MemoizedOutputCard = React.memo(OutputComponent);

function SentenceRewriter() {
  
  const [sentence, setSentence] = useState('');
  const [language, setLanguage] = useState('a735cf99d36e');
  const [mood, setMood] = useState('20d1e2ee3f6f');
  const [rewrittenSentence, setRewrittenSentence] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const determineTextDirection = useCallback((text) => {
    const rtlChars = /^[א-ת\u0600-\u06FF]/; // Regex to detect Hebrew and Arabic characters
    const startsWithRTL = rtlChars.test(text.trim()[0]);
    return startsWithRTL ? { direction: 'rtl', textAlign: 'right' } : { direction: 'ltr', textAlign: 'left' };
  }, []);


  const sentences = [
    "What would you like to say?",
    "What would you like to rephrase?",
    "Your thoughts, My words!",
    "You can write in any language...",
    "Any thoughts you wish to express?"
  ];

  

  const [textStyle, setTextStyle] = useState(determineTextDirection(sentence));

  useEffect(() => {
    setTextStyle(determineTextDirection(sentence));

    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        setFade(true);
      }, 600); // this timeout should be less than the interval to allow for fade-out
    }, 8000); // changes every 3 seconds

    return () => clearInterval(interval);

  }, [sentence, determineTextDirection]);

  const handleRewrite = useCallback(() => {

    if (!sentence) {
        let randomIndex = Math.floor(Math.random() * errorMessages.length);
        setError(errorMessages[randomIndex]);
      return;
    }

    setRewrittenSentence('');
    setLoading(true);
    
    axios.post(process.env.REACT_APP_API_URL + '/rewrite', { sentence, language, mood })
      .then(response => {
        setRewrittenSentence(response.data.rewritten_sentence);
        setError('');
        setLoading(false);
      })
      .catch(err => {
        setError(`Whoops! Our tech gremlins struck again!`);
        console.error('API error:', err);
        setLoading(false);
      });

  }, [sentence, language, mood]);

  return (
    <Container maxWidth="auto">
      <Box sx={{marginTop: "20px"}}>
        <Typography variant="body" sx={{ fontSize: { xs: '18px', sm: '16px' }, marginBottom: "0", fontWeight: "500" }}>
        {sentences[index]}
        </Typography>

        <UserSentenceComponent 
          sentence={sentence} 
          onChange={(e) => setSentence(e.target.value)} 
          textStyle={textStyle}
        />

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: {
            xs: '5px', 
            sm: '16px' 
          },
          flexDirection: {
            xs: 'column', 
            sm: 'row' 
          } }}>
          
          <LanguageComponent value={language} onChange={setLanguage} />
          <ToneComponent value={mood} onChange={setMood} />
        </Box>

        <Box sx={{ 
          position: 'relative', 
          width: '100%', 
          marginBottom: {
              xs: '20px',
              sm: '20px'
            } }}>
          <ActionButton handleRewrite={handleRewrite} loading={loading} />
        </Box>

        {error && (
          <Typography color="#d42160" style={{ 
            marginTop: '15px', 
            marginBottom: {
              xs: '20px',
              sm: '20px'
            }, 
            fontSize: '18px',
            fontWeight: '500',
            background: '#eef1f1', 
            borderColor: '#D42160',
            border: '1px solid #fff',
            borderRadius: '5px', padding: '10px', display: 'flex', justifyContent: 'center' }} className={error ? 'shake-animation' : ''} >
            👾 {error}
          </Typography>
        )}

        <MemoizedOutputCard text={rewrittenSentence} />
      </Box>
    </Container>
  );
}

export default SentenceRewriter;
