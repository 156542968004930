import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import confetti from 'canvas-confetti';
import moreDramaticMessages from '../staticText/moreDramaticMessages';
import lessDramaticMessages from '../staticText/lessDramaticMessages';

function ActionButton({ handleRewrite, loading }) {
    const [buttonText, setButtonText] = useState('Slick It!');
    const timeoutRef = useRef(null);
    const prevLoading = useRef(loading);
    const launchCounter = useRef(0); // Counter to control confetti frequency

    useEffect(() => {
        if (!loading && prevLoading.current) {
            launchCounter.current += 1; // Increment counter each time button becomes enabled

            if (launchCounter.current === 1 || launchCounter.current % Math.round((Math.random() * 5 + 10))  === 0) { // First time or every 10-15 time
                handleConfetti();
            }
        }
        prevLoading.current = loading; // Update the previous loading state

        if (loading) {
            setButtonText(lessDramaticMessages[Math.floor(Math.random() * lessDramaticMessages.length)]);

            timeoutRef.current = setTimeout(() => {
                if (loading) { // Ensure it's still loading before changing the text
                    setButtonText(moreDramaticMessages[Math.floor(Math.random() * moreDramaticMessages.length)]);
                } else {
                    setButtonText('Slick It!');
                }
            }, 3500);
        } else {
            setButtonText('Slick It!');
        }

        return () => clearTimeout(timeoutRef.current); // Cleanup the timeout on unmount or when loading changes
    }, [loading]);

    const handleConfetti = () => {
        confetti({
            particleCount: 500,
            spread: 1360,
            origin: { y: 0.5 },
            zIndex: 1000,
            colors: ['#afccd6', '#c1e0eb', '#D8E9EE', '#EEF1F1', '#E6EEF0', '#266087', '#15364c', '#040B10'],
            scalar: 0.7,
            ticks: 1800,
            decay: 1.0,
            disableForReducedMotion: true
        });
    };

    const handleClick = () => {
        handleRewrite();
    };

    return (
        <Box>
            <Button 
                variant="contained"
                onClick={handleClick}
                fullWidth
                disabled={loading}
                sx={{ 
                    marginTop: '20px', 
                    padding: '10px', 
                    color: '#ffffff',
                    backgroundColor: '#266087',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    '&.Mui-disabled': {
                        backgroundColor: '#266087', 
                        color: 'white'
                    },
                    '&:hover': {
                        backgroundColor: '#266087',
                    },
                }}
            >
                {buttonText}
            </Button>
            {loading && (
                <LinearProgress 
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '3px',
                        backgroundColor: 'rgba(0, 0, 0, 0)', 
                        color: '#040b10'
                    }}
                    sx={{
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: '#040b10'
                        }
                    }}
                />
            )}
        </Box>
    );
}

export default ActionButton;
