import React from 'react';
import SentenceRewriter from './components/SentenceRewriter';
import { Box, Typography } from '@mui/material';
import IntroText from './components/IntroText';
import LogoImage from './components/LogoImage';

function App() {
  // Define the breathing gradient effect as a dynamic background
  const dynamicBackground = {
    backgroundImage: `radial-gradient(circle, #DFEFF5, #e6eef0)`,
    backgroundSize: '150% 150%',
    animation: 'breathe 8s ease-in-out infinite'
  };

  return (
    <Box>
        <Box sx={{
              backgroundColor: '#040B10',
              width: '100%',
              maxWidth: '100%',
              boxSizing: 'border-box',
              padding: '10px 5px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
          <Typography variant="h1" sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#C1E0EB',
              textAlign: 'left',
              paddingLeft: '10px',
              display: {
                xs: 'none',
                sm: 'flex'
              },
              flex: 1,
            }}>Version 1.18
          </Typography>
          <Typography variant="h1" sx={{
              fontSize: '18px',
              fontWeight: '500',
              color: '#fff',
              textAlign: 'center',
              flex: 1,
            }}>The Number #1 Paraphrasing Tool
          </Typography>
          <Typography variant="h1" sx={{
              fontSize: '18px',
              fontWeight: '500',
              color: '#fff',
              textAlign: 'right',
              flex: 1,
              display: {
                xs: 'none',
                sm: 'flex'
              },
            }}>
          </Typography>
        </Box>
    
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        minHeight: '800px',
        width: '100vw',
        padding: {
          xs: '10px', // mobile screens
          sm: '20px', // tablets and above
        },
        boxSizing: 'border-box',
        backgroundColor: '#040B10',
        color: '#040B10',
        ...dynamicBackground
      }}>

        <style>{`
          @keyframes breathe {
            0%, 100% {
              backgroundSize: 150% 150%;
              opacity: 1;
            }
            50% {
              backgroundSize: 100% 100%;
              opacity: 1;
            }
          }
        `}</style>

        <LogoImage />
        <IntroText />

        <Box sx={{
          padding: {
            xs: '15px', // mobile screens
            sm: '20px', // tablets and above
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '400px',
          width: {
            xs: '100%', // mobile screens
            sm: '800px', // tablets and above
          },
          color: '#333',
          backgroundColor: '#fff',
          boxSizing: 'border-box',
          borderRadius: {
            xs: '20px', // mobile screens
            sm: '10px', // tablets and above
          },
          border: '1px solid #E6E8E8',
          transition: 'all 0.6s ease-out'
        }}
        >
          <SentenceRewriter />
        </Box>


      </Box>
      <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '500px'
      }}>
        <section class="about">
          <h1>Enhance Any Text with SayItSlick!</h1>
          <p>SayItSlick was designed for those who like to get to the point without spending too much time on crafting the perfect wording.</p>
          <Box sx={{
      height: '100%', // Ensure the container fills its parent
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <img
        src="femail-employee-using-sayitslick.png"
        alt="A happy employee enjoying SayItSlick"
        title="SayItSlick is Cool and Easy to use!"
        style={{
          maxHeight: '550px',
          maxWidth: '80%'
        }}
      />
    </Box>
          
        </section>
        </Box>
    </Box>
  );
}

export default App;
