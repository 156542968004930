import React from 'react';
import Badge from '@mui/material/Badge';

const BadgeComponent = ({ 
  badgeContent = "Pro", 
  backgroundColor = "#83c5be", 
  color = "#fff", 
  right = "40px" 
}) => {
  return (
    <Badge 
      badgeContent={badgeContent} 
      sx={{ 
        position: 'absolute', 
        right: right, 
        '& .MuiBadge-badge': {
          backgroundColor: backgroundColor,
          color: color, // Set the text color to white for better contrast
        }
      }}
    />
  );
};

export default BadgeComponent;
