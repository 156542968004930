import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

function UserSentenceComponent({ value, onChange, textStyle }) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  const handleClear = () => {
    setInputValue('');
    onChange({ target: { value: '' } });
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        margin="normal"
        sx={{
          paddingRight: '40px', // Adjust padding to avoid overlap
          marginBottom: '5px',
          marginTop: '10px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          '& .MuiOutlinedInput-root': {
            paddingRight: '40px', // Adjust padding to avoid overlap
            fontSize: {
              xs: '18px',
              sm: '16px',
            },
            fontWeight: '400',
            '& fieldset': {
              borderColor: '#AFCCD6',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#AFCCD6',
              borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#AFCCD6',
              borderWidth: '1px',
            },
          },
        }}
        style={{
          transition: 'all 0.3s ease-out',
          ...textStyle,
        }}
      />
      <IconButton
        onClick={handleClear}
        sx={{
          position: 'absolute',
          top: 20,
          right: 5,
        }}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}

export default UserSentenceComponent;
