import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const titles = [
    "Fantastic! See how it goes:",
    "Curious? Let's find out:",
    "Who knows? Might work:",
    "Sounds good to me!",
    "Just a thought:",
    "Check this out!",
    "Fingers crossed!",
    "And... Voila!",
    "Take a look:",
    "Drumroll, please:",
    "Here we go:",
    "Hmm... Interesting:",
    "Let's see what happens:",
    "Brace yourself:",
    "Ready or not?",
    "Here's what I got:",
    "Here's something:",
    "Expect the unexpected:"
];

function OutputComponent({ text }) {
    const [copied, setCopied] = useState(false);
    const fade = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, reset: true });
    const outputRef = useRef(null);

    const determineTextDirection = (text) => {
        const rtlChars = /^[א-ת\u0600-\u06FF]/;
        const startsWithRTL = rtlChars.test(text.trim()[0]);
        return startsWithRTL ? { direction: 'rtl', textAlign: 'right' } : { direction: 'ltr', textAlign: 'left' };
    };

    const textIsRTL = determineTextDirection(text).direction === 'rtl';

    const [textStyle, setTextStyle] = useState(determineTextDirection(text));
    const [title, setTitle] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTextStyle(determineTextDirection(text));
        setTitle(titles[Math.floor(Math.random() * titles.length)]);
        if (outputRef.current) {
            const offset = window.innerWidth < 600 ? 50 : 250; // 50px for mobile, 100px for desktop
            const topPosition = outputRef.current.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    }, [text]);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            {text && (
                <Box ref={outputRef} sx={{ mt: 5, marginBottom: '20px' }}>
                    <Typography variant="body" sx={{ fontSize: { xs: '20px', sm: '18px' }, marginBottom: "15px", fontWeight: "600" }}>
                        {title}
                    </Typography>
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: 'background.paper',
                            borderRadius: '7px',
                            border: '1px solid #AFCCD6',
                            position: 'relative',
                            height: 'auto',
                            minHeight: '100px',
                            overflowY: 'scroll',
                            marginTop: '10px'
                        }}
                    >
                        <animated.div style={fade}>
                            <div
                                style={{
                                    marginBottom: "20px",
                                    fontSize: '18px',
                                    marginRight: '40px',
                                    fontStyle: "italic",
                                    ...textStyle
                                }}
                            >
                                {renderTextWithLineBreaks(text)}
                            </div>
                        </animated.div>
                        <CopyToClipboard text={text} onCopy={handleCopy}>
                            <IconButton aria-label="copy" sx={{ position: 'absolute', top: 6, right: '10px', marginLeft: '25px', color: '#afccd6' }}>
                                <ContentCopyIcon sx={{ fontSize: '0.8em', fontWeight: '300' }} />
                            </IconButton>
                        </CopyToClipboard>

                        {copied ? (
                            <Box sx={{ backgroundColor: '#afccd6', color: '#333', padding: '10px', position: 'absolute', top: -20, right: 0, borderRadius: 6, opacity: 0.85 }}>
                                Copied!
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            )}
        </>
    );
}

export default OutputComponent;
