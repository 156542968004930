// LanguageComponent.js
import React from 'react';
import { Typography, FormControl, Select, MenuItem, Badge } from '@mui/material';

const LanguageComponent = ({ value, onChange }) => (
  <FormControl fullWidth margin="normal">
    <Typography variant="body" sx={{ fontSize: { xs: '18px', sm: '16px' }, marginBottom: { xs: '5px', sm: '10px' }, marginTop: { xs: '0', sm: '10px' }, fontWeight: "500" }}>
      Output Language
    </Typography>
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#AFCCD6',
          borderWidth: '1px'
        }
      }}
      style={{
        transition: 'all 0.3s ease-out'
      }}
    >
      <MenuItem value="a735cf99d36e">English US</MenuItem>
      <MenuItem value="829a24c7c322">English UK</MenuItem>
      <MenuItem value="75edfd85fd9b">French</MenuItem>
      <MenuItem value="5fe308f91ab2">Italian</MenuItem>
      <MenuItem value="fab791237a06">German</MenuItem>
      <MenuItem value="e0dc6eeadd88">Spanish</MenuItem>
      <MenuItem value="7354db1127bc">Arabic (Spoken) <Badge badgeContent="Experimental!" color="secondary" sx={{ position: 'absolute', right: '50px'}}></Badge></MenuItem>
      <MenuItem value="77b38da4d0bc">Arabic (Written) <Badge badgeContent="Experimental!" color="secondary" sx={{ position: 'absolute', right: '50px'}}></Badge></MenuItem>
      <MenuItem value="e9cad0b35f26">Hebrew <Badge badgeContent="Experimental!" color="secondary" sx={{ position: 'absolute', right: '50px'}}></Badge></MenuItem>
      
    </Select>
  </FormControl>
);

export default LanguageComponent;
