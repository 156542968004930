import React from 'react';
import Box from '@mui/material/Box';
import logoIcon from '../assets/logo-symbol.png';
import logoSymbol from '../assets/logo-text.png';
const LogoImage = () => {
  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
    <Box
      component="img"
      src={logoIcon}
      alt="SayItSlick AI Logo"
      sx={{
        width: {
          xs: '110px',
          sm: '105px'
        },
        height: {
          xs: '110px',
          sm: '105px'
        },
        marginTop: {
          xs: '30px',
          sm: '40px'
        },
        marginBottom: {          
          xs: '30px',
          sm: '30px'
        },

        animation: 'bounce 10s infinite', // Apply the bounce animation
        '@keyframes bounce': { // Define the keyframes for bounce animation
          '0%, 100%': {
            transform: 'translateY(1) rotate(0deg) scale(1)',
          },
          '20%': {
            transform: 'translateY(-8px) rotate(-3deg) scale(1.1)',
          },
          '40%': {
            transform: 'translateY(-14px) rotate(3deg) scale(1.03)',
          },
          '60%': {
            transform: 'translateY(-8px) rotate(-2deg) scale(0.95)',
          },
          '80%': {
            transform: 'translateY(-3px) rotate(1deg) scale(1.01)',
          }
        }
      }}
    />
        <Box
      component="img"
      src={logoSymbol}
      alt="SayItSlick AI Logo"
      sx={{
        width: {
          xs: '170px',
          sm: '255px'
        },
        height: {
          xs: '170px',
          sm: '255px'
        },
        marginTop: {
          xs: '-175px',
          sm: '-255px'
        },
        marginBottom: {          
          xs: '30px',
          sm: '30px'
        },
        zIndex: '1000'
        
      }}
    />
    </Box>
  );
};

export default LogoImage;
